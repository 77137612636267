<template>
    <!-- Page-Title -->
    <div class="row">
        <div class="col-sm-12">
            <div class="page-title-box">
                <h2 class="page-title">Rekap Register Ibu Hamil</h2>
            </div>
        </div>
    </div>
    <!-- end page title end breadcrumb -->
    <div class="row">
        <div class="col-sm-12">
            <div class="row">
                <div class="col-lg-3 col-sm-12">
                    <div class="row">
                        <div class="col-xl-12 col-sm-12">
                            <div class="card overflow-hidden">
                                <div class="card-heading bg-light">
                                    <h3 class="card-title text-uppercase">Filter Data</h3>
                                    <p class="card-sub-title font-13 text-muted">
                                        Filter Rekap Ibu Hamil
                                    </p>
                                </div>
                                <div class="card-body">
                                    <div class="radio radio-custom ml-2 mb-2">
                                        <input type="radio" v-model="filter_by" id="puskesmas" value="puskesmas" />
                                        <label for="puskesmas" class="text-uppercase">
                                            Filter Per Puskesmas
                                        </label>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-2">
                                            <div class="checkbox checkbox-custom checkbox-single ml-2 mt-2">
                                                <input type="checkbox" :disabled="filter_by !== 'puskesmas'"
                                                    id="filDesa" v-model="is_per_pkm"
                                                    aria-label="Single checkbox Two" />
                                                <label></label>
                                            </div>
                                        </div>
                                        <div class="col-md-10">
                                            <Select2 :disabled="filter_by !== 'puskesmas' || !is_per_pkm"
                                                v-model="pkm_kode" :options="puskesmas" />
                                        </div>
                                    </div>
                                    <div class="radio radio-custom ml-2 mt-3">
                                        <input type="radio" v-model="filter_by" id="wilayah" value="wilayah" />
                                        <label for="wilayah" class="text-uppercase">
                                            Filter Per wilayah
                                        </label>
                                    </div>
                                    <div class="row mt-2 mb-2">
                                        <div class="col-xl-12 col-sm-12">
                                            <label class="control-label">Tanggal Awal</label>
                                            <Datepicker :format="formatStart" locale="id" v-model="dateStart"
                                                cancelText="Batal" selectText="Pilih" />
                                        </div>
                                    </div>
                                    <div class="row mt-2 mb-2">
                                        <div class="col-xl-12 col-sm-12">
                                            <label class="control-label">Tanggal Akhir</label>
                                            <Datepicker :format="formatEnd" locale="id" v-model="dateEnd"
                                                cancelText="Batal" selectText="Pilih" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- end col -->
                    </div>
                    <div class="row">
                        <div class="col-xl-12 col-sm-12">
                            <button @click="showData()"
                                class="btn btn-custom btn-block waves-effect waves-light w-lg m-b-5"
                                :disabled="is_loading">
                                <span :class="{ 'spinner-grow spinner-grow-sm mr-2': is_loading }" role="status"
                                    aria-hidden="true"></span>{{ is_loading ? "Loading..." : "Tampilkan Data" }}
                            </button>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-xl-12 col-sm-12">
                            <button @click="exportData()"
                                class="btn btn-custom btn-block waves-effect waves-light w-lg m-b-5"
                                :disabled="is_loading">
                                <span :class="{ 'spinner-grow spinner-grow-sm mr-2': is_loading }" role="status"
                                    aria-hidden="true"></span>{{ is_loading ? "Loading..." : "Export Excel" }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9 col-sm-12">
                    <div class="card overflow-hidden">
                        <div class="card-heading bg-light">
                            <h3 class="card-title text-uppercase">Grafik Data Ibu Hamil</h3>
                        </div>
                        <div class="card-body">
                            <div v-if="is_loading" class="d-flex justify-content-center">
                                <div v-for="n in 5" class="spinner-grow text-custom ml-2" role="status" :key="n">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                            <div v-else>
                                <Bar :chart-options="chartOptions" :chart-data="chartData" :chart-id="chartId"
                                    :dataset-id-key="datasetIdKey" :plugins="plugins" :css-classes="cssClasses"
                                    :styles="styles" :width="width" :height="height" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useToast } from "vue-toastification";
import axios from "axios";
import Select2 from "vue3-select2-component";
import { ref } from "vue";
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
    name: "GrafikBumil",
    components: {
        Select2,
        Bar
    },
    props: {
        chartId: {
            type: String,
            default: 'bar-chart'
        },
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        width: {
            type: Number,
            default: 0
        },
        height: {
            type: Number,
            default: 0
        },
        cssClasses: {
            default: '',
            type: String
        },
        styles: {
            type: Object,
            default: () => { }
        },
        plugins: {
            type: Object,
            default: () => { }
        }
    },
    setup() {
        const dateStart = ref(new Date());
        const dateEnd = ref(new Date());
        const toast = useToast();

        const formatStart = (dateStart) => {
            return `${dateStart.getDate()}/${dateStart.getMonth() + 1
                }/${dateStart.getFullYear()}`;
        };

        const formatEnd = (dateEnd) => {
            return `${dateEnd.getDate()}/${dateEnd.getMonth() + 1
                }/${dateEnd.getFullYear()}`;
        };

        return {
            dateStart,
            dateEnd,
            formatStart,
            formatEnd,
            toast,
        };
    },
    data() {
        return {
            filter_by: "puskesmas",
            is_per_pkm: false,
            pkm_kode: "",
            dtRekap: [],
            header: "Puskesmas",
            puskesmas: [],
            params: {},
            is_loading: false,
            chartData: {
                labels: [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December'
                ],
                datasets: [
                    {
                        label: 'Data One',
                        backgroundColor: '#dd19f7',
                        data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11]
                    }
                ]
            },
            chartOptions: {
                responsive: true
            }
        };
    },
    methods: {
        async getPuskesmas() {
            await axios
                .get(`dasar/puskesmas`)
                .then((response) => {
                    this.puskesmas = response.data.content.puskesmas.map((pkm) => ({
                        id: pkm.Kode,
                        text: pkm.Puskesmas.toUpperCase(),
                    }));
                    this.pkm_kode = this.puskesmas[0].id;
                })
                .catch((e) => {
                    this.toast.error(e.response.data.errors.message.description);
                });
        },
        async showData() {
            this.is_loading = true;
            this.buildFilter();
            await axios
                .get(`rekap/bumil`, { params: this.params })
                .then((response) => {
                    this.is_loading = false;
                    this.dtRekap = response.data.content;
                })
                .catch((e) => {
                    this.is_loading = false;
                    this.toast.error(e.response.data.errors.message.description);
                });
        },
        async buildFilter() {
            switch (this.filter_by) {
                case "wilayah":
                    this.header = "Kecamatan";
                    this.params = {
                        filtBy: btoa("isKecamatan"),
                        date_start: this.dateStart.toISOString().slice(0, 10),
                        date_end: this.dateEnd.toISOString().slice(0, 10),
                    };
                    break;
                case "puskesmas":
                    if (this.is_per_pkm) {
                        this.header = "Desa";
                        this.params = {
                            filtBy: btoa("isDesa"),
                            kd_pkm: this.pkm_kode,
                            date_start: this.dateStart.toISOString().slice(0, 10),
                            date_end: this.dateEnd.toISOString().slice(0, 10),
                        };
                    } else {
                        this.header = "Puskesmas";
                        this.params = {
                            filtBy: btoa("isPuskesmas"),
                            date_start: this.dateStart.toISOString().slice(0, 10),
                            date_end: this.dateEnd.toISOString().slice(0, 10),
                        };
                    }
                    break;
            }
        },
        exportData() {
            const params = new URLSearchParams(this.params);
            window.open(
                `${process.env.VUE_APP_APIURI}rekap/bumil/export?${params.toString()}`,
                "_blank"
            );
            this.is_loading = false;
        },
    },
    watch: {
        filter_by() {
            if (this.filter_by == "wilayah") {
                this.is_per_pkm = false;
            }
        },
        is_loading() { },
    },
    created() {
        this.getPuskesmas();
    },
};
</script>

<style>

</style>
